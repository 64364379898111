

export class Contact
{
  email: string = ''
  phone: string = ''
  address: string = ''
  address_2: boolean = false
  openingHours: Array<any> = []
  
  constructor(apiObject: any)
  {
    Object.assign(this, apiObject)
  }



}

