<template>
  <VSection :id="id" class="full-height section-bg-logo">
    <div class="first-section">
      <div class="first-section-left">
        <slot name="left" />
      </div>
      <div class="first-section-right">
        <slot name="right" />
      </div>
    </div>

    <div class="arrow-down" @click="scrollToNextSection()">
      <img src="/img/icons/chevron-down-solid.svg">
    </div>
  </VSection>
</template>

<script>
import VSection from '@/components/VSection'
import { Vue, Component, Prop } from 'vue-property-decorator'



@Component({
  components: {
    VSection
  }
})
export default class FirstSection extends Vue {
  @Prop({ default: '', type: String }) id

  data() {
    return {

    }
  }

  scrollToNextSection()
  {
    const el = document.getElementsByTagName('section')[1];
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

}
</script>
