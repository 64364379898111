

export class Site
{
  name: string = ''
  url: string = ''
  description: string = ''
  maintenance: boolean = false
  
  constructor(apiObject: any)
  {
    Object.assign(this, apiObject)
  }



}

