<template>
  <router-link
    v-if="!menuItem.link.isExternal"
    :id="`menu-item-${menuItem.id}`"
    class="menu-item"
    :rel="menuItem.link.rel"
    :alt="menuItem.link.alt"
    :to="menuItem.link.getHref()">
    {{ menuItem.link.label }}
  </router-link>
  <a
    v-else
    :id="`menu-item-${menuItem.id}`"
    class="menu-item"
    :rel="menuItem.link.rel"
    :alt="menuItem.link.alt"
    :href="menuItem.link.getHref()">
    {{ menuItem.link.label }}
  </a>
</template>
<script>
import { MenuItem } from '@/models/MenuItem'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({

})
export default class VMenuItem extends Vue {
  @Prop({ default: false, type: MenuItem }) menuItem


}
</script>
