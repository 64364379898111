
<template>
  <div id="app">
    <FullLoader v-show="isLoading" />
    <VHeader />
    <Maintenance v-if="isInMaintenance" />
    <transition v-else name="fade-blur">
      <router-view />
    </transition>
    <VFooter />
  </div>
</template>

<script>

import { Component } from 'vue-property-decorator'
import './scss/style.scss'
import VHeader from '@/components/VHeader'
import VFooter from '@/components/VFooter'
import FullLoader from '@/components/FullLoader'
import GlobalMixin from '@/mixins/global.mixin'
import Maintenance from '@/pages/Maintenance.vue'

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
])

@Component({
  components: {
    VHeader,
    VFooter,
    FullLoader,
    Maintenance
  }
})
export default class App extends GlobalMixin {

  mounted(){
    this.loadSite()
  }

  beforeRouteEnter(to, from, next) {
    this.updatePageTitle()
    next()
  }


  loadSite()
  {
    this.$store.dispatch('loadSite')
      .then(() => {
        this.updatePageTitle()
      })
      .catch(err => {
        this.$router.push({name:'error', props: { msg: err.message }})
      })
  }



  get isLoading()
  {
    return this.$store.getters.getIsLoading || !this.$store.getters.isPageLoaded
  }

  get isInMaintenance()
  {
    return this.$store.getters.getSiteInfos.maintenance
  }
}

</script>
