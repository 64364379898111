//----------------------------------------
//-- All Rights Reserved © 2021
//-- RM Agency (Romain Meunier)
//-- For Eleyse Beauté (Institut Sophie)
//----------------------------------------


export const Strapi = {
  hostname: 'https://strapi.romainmnr.com/institutsophie.fr'
}

export const GoogleMapConfig = {
	apiKey: 'AIzaSyA8yitkctq3iYH7tFK9pWxdtT0XVzc-sws',
	version: 'weekly',
};