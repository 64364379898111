<template>
  <PrestationsPage
    title_1="Mains"
    title_2="& pieds"
    :sections="sections"
    :images="images" />
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import PrestationsPage from '@/components/PrestationsPage'

@Component({
  components: {
    PrestationsPage
  }
})

export default class Page extends GlobalMixin {

  data() {
    return {
      images: undefined,

      sections: [
        {
          title: '',
          subSections: [
            {
              title: '',
              items: [
                {
                  name: 'Manucure flash',
                  alternatives: [
                    {
                      description: '2 zones',
                      duration: '',
                      price: '15€',
                    }
                  ]
                },
                {
                  name: 'Pose de vernis classique',
                  alternatives: [
                    {
                      description: '',
                      duration: '20 min',
                      price: '15€',
                    }
                  ]
                },
                {
                  name: 'Soin des mains',
                  alternatives: [
                    {
                      description: 'Beauté des ongles, Gommage, Masque & Massage',
                      duration: '50 min',
                      price: '34€',
                    },
                    {
                      description: '+ pose de vernis',
                      duration: '',
                      price: '45€',
                    }
                  ]
                },
                {
                  name: 'Soin des pieds',
                  alternatives: [
                    {
                      description: 'SPA, beauté des ongles, Gommage, Masque & Massage',
                      duration: '50 min',
                      price: '39€',
                    }
                  ]
                },
                {
                  name: 'Yumi Feet',
                  alternatives: [
                    {
                      description: 'Soin peeling des pieds',
                      duration: '45 min',
                      price: '39€',
                    },
                    {
                      description: '+ Soin des pieds',
                      duration: '1h15',
                      price: '55€',
                    },
                    {
                      description: '+ pose de vernis semi-permanent',
                      duration: '1h15',
                      price: '59€',
                    },
                    {
                      description: '+ dépose + pose de vernis semi-permanent',
                      duration: '1h30',
                      price: '69€',
                    }
                  ]
                },
                {
                  name: 'Pose de vernis semi-permanent',
                  alternatives: [
                    {
                      description: '',
                      duration: '45 min',
                      price: '32€',
                    }
                  ]
                },
                {
                  name: 'Dépose & pose de vernis semi-permanent',
                  alternatives: [
                    {
                      description: '',
                      duration: '60 min',
                      price: '42€',
                    }
                  ]
                },
                {
                  name: 'Dépose de vernis semi-permanent & soin des ongles',
                  alternatives: [
                    {
                      description: '',
                      duration: '30 min',
                      price: '15€',
                    }
                  ]
                },
              ]
            } ,
            {
              title: 'Prothésie ongulaire',
              items: [
                {
                  name: 'Pose de gel',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '47€',
                    }
                  ]
                },
                {
                  name: 'Extension',
                  alternatives: [
                    {
                      description: '(Sur chablons)',
                      duration: '',
                      price: '58€',
                    }
                  ]
                },
                {
                  name: 'Pose de gel & couleur / French',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '57€',
                    }
                  ]
                },
                {
                  name: 'Pose de gel & extension & couleur / French',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '68€',
                    }
                  ]
                },
                {
                  name: 'Dépose de gel',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '25€',
                    }
                  ]
                },

                {
                  name: 'Réparation à l\'unité',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '5€',
                    }
                  ]
                },
                {
                  name: 'Déco à l\'unité',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '0,50€',
                    }
                  ]
                },
              ]
            }
              
      

          ]
        },
      ],

    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
