<template>
  <div class="image-wrapper-2" :class="{hover: hover }">
    <img :src="src">
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({

})
export default class ImageControl extends Vue {
  @Prop({ default: false, type: Boolean }) hover
  @Prop({ default: '', type: String }) src



}
</script>
