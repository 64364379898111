<template>
  <header>
    <div v-if="!isDefaultLayout" class="logo">
      <router-link to="/">
        <img class="main-txt-logo" src="/img/sophie.png">
      </router-link>
    </div>
    <transition name="fade-blur">
      <nav class="menu" :class="{leftAligned: !isDefaultLayout}">
        <VMenuItem
          v-for="menuItem in leftMenu"
          :key="`main-menu-${menuItem.id}`"
          :menu-item="menuItem" />

        <div v-if="isDefaultLayout" class="menu-item-logo">
          <img src="/img/sophie-icon.png">
        </div>
        <VMenuItem
          v-for="menuItem in rightMenu"
          :key="`main-menu-${menuItem.id}`"
          :menu-item="menuItem" />
      </nav>
    </transition>
  </header>
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin'
import VMenuItem from '@/components/VMenuItem'
import { Component } from 'vue-property-decorator'
@Component({
  
  components: {
    VMenuItem
  }
})
export default class VHeader extends GlobalMixin {

  get isSmallWidth () {
    return window.innerWidth < 600
  }

  get isHomePage()
  {
    return this.currentPageUid === this.HOME_UID
  }

  get isDefaultLayout() {
    return this.isSmallWidth || this.isHomePage
  }

  get menuItems()
  {
    return this.$store.getters.mainMenuItems
  }

  get leftMenu()
  {
    return this.menuItems.slice(0, Math.floor(this.menuItems.length / 2))
  }
  get rightMenu()
  {
    return this.menuItems.slice(Math.floor(this.menuItems.length / 2))
  }


}
</script>
