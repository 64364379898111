<template>
  <PrestationsPage
    title_1="Les"
    title_2="épilations"
    :sections="sections"
    :images="images" />
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import PrestationsPage from '@/components/PrestationsPage'

@Component({
  components: {
    PrestationsPage
  }
})

export default class Page extends GlobalMixin {

  data() {
    return {
      images: undefined,

      sections: [
        {
          title: '',
          subSections: [


            {
              title: 'Visage',
              items: [
                {
                  name: 'Lèvres',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '11.5€',
                    }
                  ]
                },
                {
                  name: 'Menton ou joues',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '13.5€',
                    }
                  ]
                },
                {
                  name: 'Sourcils',
                  alternatives: [
                    {
                      description: 'Création',
                      duration: '',
                      price: '16€',
                    },
                    {
                      description: 'Entretien',
                      duration: '',
                      price: '12.5€',
                    }
                  ]
                },
                {
                  name: 'Sourcils & lèvres',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '19€',
                    }
                  ]
                },
                {
                  name: 'Lèvres & menton',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '20€',
                    }
                  ]
                },
                {
                  name: 'Sourcils, Lèvres & menton',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '25€',
                    }
                  ]
                },
                {
                  name: 'Visage',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '28€',
                    }
                  ]
                }
              ]
            } ,
            {
              title: 'Corps',
              items: [
                {
                  name: 'Aisselles',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '15€',
                    }
                  ]
                },
                {
                  name: 'Maillot simple',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '15€',
                    }
                  ]
                },
                {
                  name: 'Maillot Brésilien',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '19€',
                    }
                  ]
                },
                {
                  name: 'Maillot semi-intégral',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '28€',
                    }
                  ]
                },
                {
                  name: 'Maillot intégral',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '30€',
                    }
                  ]
                },
                {
                  name: '½ jambes',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '21€',
                    }
                  ]
                },
                {
                  name: 'Cuisses',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '32€',
                    }
                  ]
                },
    
                {
                  name: 'Jambes complètes',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '15€',
                    }
                  ]
                },
                {
                  name: 'Bras complèts',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '17€',
                    }
                  ]
                },
                {
                  name: '½ bras',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '14€',
                    }
                  ]
                },
              ]
            } ,
            {
              title: 'Hommes',
              items: [
                {
                  name: 'Nez ou oreilles',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '10€',
                    }
                  ]
                },
                {
                  name: 'Sourcils',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '13€',
                    }
                  ]
                },
                {
                  name: 'Aisselles',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '15€',
                    }
                  ]
                },
                {
                  name: 'Bras',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '19€',
                    }
                  ]
                },
                {
                  name: 'Torse ou dos',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '30€',
                    }
                  ]
                },
                {
                  name: 'Torse & dos',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '55€',
                    }
                  ]
                },
                {
                  name: 'Jambes complètes',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '35€',
                    }
                  ]
                }
              ]
            } ,
            {
              title: 'Forfaits',
              items: [
                {
                  name: '½ jambes',
                  alternatives: [
                    {
                      description: '+ aisselles + maillot.',
                      duration: '',
                      price: '45€',
                    },
                    {
                      description: '+ aisselles + maillot Brésilien.',
                      duration: '',
                      price: '49€',
                    },
                    {
                      description: '+ aisselles + maillot semi-intégral.',
                      duration: '',
                      price: '53€',
                    },
                    {
                      description: '+ aisselles + maillot intégral',
                      duration: '',
                      price: '56€',
                    },
                  ]
                },
                {
                  name: 'Jambes complètes',
                  alternatives: [
                    {
                      description: '+ aisselles + maillot.',
                      duration: '',
                      price: '53€',
                    },
                    {
                      description: '+ aisselles + maillot Brésilien.',
                      duration: '',
                      price: '56€',
                    },
                    {
                      description: '+ aisselles + maillot semi-intégral.',
                      duration: '',
                      price: '60€',
                    },
                    {
                      description: '+ aisselles + maillot intégral',
                      duration: '',
                      price: '62€',
                    },

                    {
                      description: '+ maillot.',
                      duration: '',
                      price: '42€',
                    },
                    {
                      description: '+ maillot Brésilien.',
                      duration: '',
                      price: '44€',
                    },
                    {
                      description: '+ maillot semi-intégral.',
                      duration: '',
                      price: '50€',
                    },
                    {
                      description: '+ maillot intégral',
                      duration: '',
                      price: '53€',
                    },
                  ]
                }
              ]
            } ,

            {
              title: 'Lumière pulsée',
              subtitle: 'Consultation et Devis GRATUIT et sur rendez-vous.',
              items: [
                {
                  name: '',
                  alternatives: [
                    {
                      description: 'La séance',
                      duration: '',
                      price: '45€ à 245€',
                    }
                  ]
                }
              ]
            } ,

              
      

          ]
        },
      ],

    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
