<template>
  <PrestationsPage
    title_1="Embellisement"
    title_2="du regard"
    :sections="sections"
    :images="images" />
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import PrestationsPage from '@/components/PrestationsPage'

@Component({
  components: {
    PrestationsPage
  }
})

export default class Page extends GlobalMixin {

  data() {
    return {
      images: undefined,

      sections: [
        {
          title: '',
          subSections: [
            {
              title: '',
              items: [
                {
                  name: 'Teinture de sourcils',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '20€',
                    }
                  ]
                },
                {
                  name: 'Teinture de cils',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '26€',
                    }
                  ]
                },
                {
                  name: 'Teinture de cil & sourcils',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '39€',
                    }
                  ]
                },
                {
                  name: 'Teinture & épilation des sourcils',
                  alternatives: [
                    {
                      description: '',
                      duration: '',
                      price: '29€',
                    }
                  ]
                },
                {
                  name: 'Réhaussement de cils "Yumi Lash"',
                  alternatives: [
                    {
                      description: 'Réhaussement & teinture',
                      duration: '1h30',
                      price: '80€',
                    },
                    {
                      description: 'Réhaussement, teinture & épilation des sourcils',
                      duration: '1h45',
                      price: '90€',
                    }
                  ]
                }
              ]
            } 
              
      

          ]
        },
      ],

    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
