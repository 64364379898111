import Vue from 'vue'
import { ImageLink } from '@/models/ImageLink'

export default class GlobalMixin extends Vue {

  get HOME_UID () 
  {
    return 'accueil'
  }

  get currentPageUid (): string {
    return this.$route.params.pUid || this.HOME_UID
  }

  get menuItems()
  {
    return this.$store.getters.footerMenuItems
  }
  
  get siteInfos()
  {
    return this.$store.getters.getSiteInfos
  }
  
  get page()
  {
    return this.$store.getters.getPage
  }

  get firstSection()
  {
    return this.page?.firstSection
  }

  get sections ()
  {
    return this.page?.sections || []
  }


  updatePageTitle () {
    document.title = this.siteInfos.name
    if (this.page) {
      document.title += ` - ${this.page.name}`
    }
  }



}