<template>
  <div class="main-content">
    <FirstSiteSection id="sec-home">
      <template #left>
        <div class="main-title">
          <h2>Cartes</h2>
          <h1>Cadeaux</h1>
        </div>
      </template>
      <template #right>
        <Carousel
          
          :images="carousel.imageURLs"
          :auto-slide="carousel.autoSlide"
          :slide-interval="carousel.delay"
          :border-color="carousel.borderColor"
          :hover="carousel.hover" />
      </template>
    </FirstSiteSection>

    <VSection
      class="section-bg-green section-center"
      :boxed="true">
      <h1 class="section-title" />
      <p>
        Offrez à vos proches une expérience luxueuse et revitalisante avec notre carte cadeau pour un institut de beauté.
        Que ce soit pour célébrer une occasion spéciale, offrir un moment de détente ou simplement faire plaisir à quelqu'un de cher, notre carte cadeau est le choix parfait.
      </p>

      <div>
        <a class="btn no-width white" href="https://onlinebooking.ikosoft.com/15BBD6B232E3783D10A9D0000/FRA/vouchers" rel="nofollow noopenner">
          J'achète une carte cadeau en ligne
        </a>
      </div>


      <p class="muted">
        Les cartes cadeaux sont valable 6 mois. 
        En cas de rendez-vous non honoré la carte cadeau sera perdue. 
        Un délai de report de 3 mois sera accepter sous conditions.
      </p>
    </VSection>
  </div>
</template>

<script>
import FirstSiteSection from '@/components/FirstSection'
import VSection from '@/components/VSection'
import Carousel from '@/components/Carousel'
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import ImageControl from '@/components/ImageControl'

@Component({
  components: {
    FirstSiteSection,
    VSection,
    Carousel,
    ImageControl,
  }
})
export default class Page extends GlobalMixin {

  data() {
    return {
      images: [],
      carousel: {
        imageURLs: [
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_393847904_f1883076d7.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_223522153_5061460fbb.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_270441506_dd2ae4baf0.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_201202488_426d139d57.jpeg',

        ],
        width:"40vw",
        height:"40vh",
        autoSlide:true,
        slideInterval:4000,
        borderColor:"green",
        hover:true ,
      },

    
    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
