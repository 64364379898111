
//----------------------------------------
//-- All Rights Reserved © 2021
//-- RM Agency (Romain Meunier)
//-- For Eleyse Beauté (Institut Sophie)
//----------------------------------------

import Vue from 'vue'
import router from '@/main.routes'
import App from '@/App.vue'
import MainStore from '@/stores/main.store'


Vue.config.productionTip = false

new Vue({
  router,
  store: MainStore,
  render: h => h(App),
}).$mount('#app')
