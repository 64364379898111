<template>
  <div class="main-content">
    <FirstSiteSection id="sec-home ">
      <template #left>
        <div class="main-title">
          <h2>{{ title }}</h2>
          <h1>{{ subtitle }}</h1>
        </div>
        <div class="main-quote">
          <q>{{ msg }}</q>
        </div>
      </template>
    </FirstSiteSection>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import FirstSiteSection from '@/components/FirstSection'

@Component({
  components: {
    FirstSiteSection,
  }
})
export default class ErrorPage extends Vue {
  @Prop({ default: 'Une erreur', type: String }) title
  @Prop({ default: 'est survenue', type: String }) subtitle
  @Prop({ default: '', type: String }) msg


}

</script>
