import Vue from 'vue'
import { ActionContext } from 'vuex'
import { Site } from '@/models/Site'
import { Contact } from '@/models/Contact'
import { MenuItem } from '@/models/MenuItem'
import { Strapi } from '@/configs/Global.config'
import { API } from '@/models/API'


declare interface GlobalState
{
  isLoading: boolean
  site: Site
  contact: Contact
  menuItems: MenuItem[]
}

declare type GlobalActionContext = ActionContext<GlobalState, {}>

const state = () => ({
  isLoading: true,
  site: new Site({}),
  contact: new Contact({}),
  menuItems: []
  
})

// getters
const getters = {
  getIsLoading: (state: GlobalState) => state.isLoading,
  getSiteInfos: (state: GlobalState) => state.site,
  getContactInfos: (state: GlobalState) => state.contact,
  allMenuItems: (state: GlobalState) => state.menuItems,
  mainMenuItems: (state: GlobalState) => state.menuItems.filter((e) => e.isInMainMenu === true),
  footerMenuItems:(state: GlobalState) => state.menuItems.filter((e) => e.isInFooterMenu === true),
}

// actions
const actions = {
  
  
  loadSite (context: GlobalActionContext) {
    context.commit('setLoadingState', true)
    
    const api = new API(Strapi.hostname)
    return api.getSingleType<Site>('site')
    .then(site => {
      context.commit('setSiteInfos', site)
      return api.getAll<MenuItem>('menu-items')
    })
    .then(items => {
      context.commit('addMenuItems', items.sort((a, b) => a.order - b.order))
      return api.getSingleType<Contact>('contact')
    })
    .then(contact => {
      context.commit('setContactInfos', contact)
    })
    .finally(() => {
      Vue.nextTick(function () {
        context.commit('setLoadingState', false)
      })
    })
  },


  
}

// mutations
const mutations = {
  setLoadingState (state: GlobalState, isLoading: boolean) {
    state.isLoading = isLoading
  },
  setSiteInfos (state: GlobalState, siteInfos: Site) {
    state.site = new Site(siteInfos)
  },
  setContactInfos (state: GlobalState, contactInfos: Contact) {
    state.contact = new Contact(contactInfos)
  },
  addMenuItems (state: GlobalState, menus: Array<MenuItem>) {
    state.menuItems = state.menuItems.concat(menus.map(e => new MenuItem(e)))
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}