<template>
  <div class="carousel-wrapper">
    <div class="carousel-images">
      <transition name="carousel">
        <ImageControl
          :src="currentImage" 
          :class="borderColor" 
          :hover="hover" />
      </transition>
    </div>

    <div class="carousel-control">
      <div v-for="(imgSrc, idx) in images" :key="`carousel-${id}-ctrl-${idx}`">
        <a @click="selectImage(idx)">
          <div class="carousel-dot" :class="{active: currentIdx === idx}"><div /></div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ImageControl from '@/components/ImageControl'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    ImageControl
  }
})
export default class Carousel extends Vue {
  @Prop({ default: '', type: String }) id
  @Prop({ default: () => ([]), type: Array }) images
  @Prop({ default: '', type: String }) width
  @Prop({ default: '', type: String }) height
  @Prop({ default: '', type: String }) borderColor
  @Prop({ default: true, type: Boolean }) autoSlide
  @Prop({ default: 3000, type: Number }) slideInterval


  data() {
    return {
      currentIdx: 0,
      hover: true,
      intvl: undefined,
    }
  }

  get currentImage() {
    return this.images[this.currentIdx]
  }
  

  

  @Watch('slideInterval', { immediate: true })
  onSlideIntervalChange() {
    this.initInterval()
  }

  mounted()
  {
    this.initInterval()
  }

  beforeDestroy() 
  {
    if(this.intvl) {
      clearInterval(this.intvl)
    }
  }
  

  showNext()
  {
    if (this.currentIdx === (this.images.length -1)){
      this.selectImage(0)
    } else {
      this.selectImage(this.currentIdx + 1)
    }
  }

  selectImage(idx)
  {
    this.currentIdx = idx
  }

  initInterval () {
    if(this.intvl) {
      clearInterval(this.intvl)
    }

    if(this.autoSlide)
    {
      this.intvl = setInterval(() => {
        this.showNext()
      }, this.slideInterval);
    }
  }
}
</script>
