<template>
  <PrestationsPage
    title_1="Nos"
    title_2="Maquillages"
    :sections="sections"
    :images="images">
    <template #prestationFooter>
      <h2 class="section-title">
        Maquillage Semi-Permanent sur rendez-vous avec Marie votre Dermographe.
      </h2>
    </template>
  </PrestationsPage>
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import PrestationsPage from '@/components/PrestationsPage'

@Component({
  components: {
    PrestationsPage
  }
})

export default class Page extends GlobalMixin {

  data() {
    return {
      images: undefined,

      sections: [
        {
          title: '',
          subSections: [
            {
              title: '',
              items: [
                {
                  name: 'Maquillage jour',
                  alternatives: [
                    {
                      description: '',
                      duration: '30min',
                      price: '30€',
                    }
                  ]
                },
                {
                  name: 'Essai maquillage',
                  alternatives: [
                    {
                      description: '',
                      duration: '60min',
                      price: '39€',
                    }
                  ]
                },
                {
                  name: 'Maquillage soirée / mariage',
                  alternatives: [
                    {
                      description: '',
                      duration: '45min',
                      price: '39€',
                    }
                  ]
                },
                {
                  name: 'Forfait mariée',
                  alternatives: [
                    {
                      description: 'Essai & Jour J.',
                      duration: '',
                      price: '75€',
                    }
                  ]
                },
                {
                  name: 'Cours de Maquillage',
                  alternatives: [
                    {
                      description: '1 session',
                      duration: '60 min',
                      price: '60€',
                    },
                    {
                      description: '4 sessions • 4 thèmes',
                      duration: '',
                      price: '120€',
                    },
                 
                  ]
                },
                {
                  name: 'Cours de Maquillage "DUO"',
                  alternatives: [
                    {
                      description: 'Une bonne idée pour partager un moment complice avec votre fille ou une amie !!',
                      duration: '',
                      price: '150€',
                    }
                  ]
                }
              ]
            } 
              
      

          ]
        },
      ],

    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
