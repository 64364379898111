<template>
  <PrestationsPage
    title_1="Soins"
    title_2="Corps"
    :sections="sections"
    :images="images" />
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import PrestationsPage from '@/components/PrestationsPage'

@Component({
  components: {
    PrestationsPage
  }
})

export default class Page extends GlobalMixin {

  data() {
    return {
      images: undefined,

      sections: [
        {
          title: 'Les soins du corps',
          subSections: [
            {
              title: '',
              items: [
                {
                  name: 'Consultation',
                  alternatives: [
                    {
                      description: 'Offert pour l\'achat d\'une Cure et suivi.',
                      duration: '60 min',
                      price: '40€',
                    },
                  ]
                },
                {
                  name: 'Soin Gommant',
                  alternatives: [
                    {
                      description: '',
                      duration: '30 min',
                      price: '39€',
                    },
                  ]
                },
                {
                  name: 'Sauna Dôme',
                  alternatives: [
                    {
                      description: 'Sauna japonais aux infrarouges longs & aux pierres minérales - Relaxation, détox, amincissant',
                      duration: '30 min',
                      price: '35€ - 315€ les 10',
                    },
                    {
                      description: '+ Avec oreiller de Sophrologie (7 programmes)',
                      duration: '30 min',
                      price: '45€ - 390€ les 10',
                    },
                    {
                      description: 'Enveloppement sudatonic',
                      duration: '90 min',
                      price: '75€',
                    },
                  ]
                },


              ]
            },
            { 
              title: 'Lift & shape',
              subtitle: 'Onda Dinamica - en cure pour un traitement en profondeur',

              items: [
 
                {
                  name: 'Un dispositif unique pour tout soin du corps  ',
                  alternatives: [
                    {
                      description: '1 ou 2 zone(s) cellulite, fibrose, atonie, rétention d’eau, vascularisation, remodelage',
                      duration: '30 min',
                      price: '90€',
                    },
                    {
                      description: '',
                      duration: '60 min',
                      price: '175€',
                    }
                  ]
                },
              ]
            },

            {
              title: 'Soin minceur',
              items: [
                {
                  name: 'Soin minceur "Multi Slim" • Méthode de soin manuelle',
                  alternatives: [
                    {
                      description: 'Modelage Personnalisé et Enveloppement Minceur Anti-Graisse, Anti-Eau.',
                      duration: '60 min',
                      price: '40€',
                    },
                  ]
                },
                {
                  name: 'Jambes légères • Méthode de soin manuelle',
                  alternatives: [
                    {
                      description: 'Drainage et Enveloppement Jambes Légères.',
                      duration: '30 min',
                      price: '55€',
                    },
                  ]
                }

              ]
            },
      
            {
              title: 'Les massages du dos',
              items: [
                {
                  name: 'Modelage du dos',
                  alternatives: [
                    {
                      description: '',
                      duration: '30 min',
                      price: '39€',
                    },
                  ]
                },
                {
                  name: 'Soin détente du dos',
                  alternatives: [
                    {
                      description: 'Gommage et Modelage du Dos.',
                      duration: '45 min',
                      price: '50€',
                    },
                  ]
                },
                {
                  name: 'Soin décontractant et remineralisant',
                  alternatives: [
                    {
                      description: 'Gommage, Modelage et Enveloppement du Dos.',
                      duration: '60 min',
                      price: '60€',
                    },
                  ]
                }

              ]
            },
            {
              title: 'Les massages du corps',
              items: [
                {
                  name: '',
                  alternatives: [
                    {
                      description: '+ supplément gommage',
                      duration: '',
                      price: '30€',
                    },
                  ]
                },
                {
                  name: 'Le destressant',
                  alternatives: [
                    {
                      description: 'Massage aux huiles essentielles',
                      duration: '30 min',
                      price: '49€',
                    },
                    {
                      description: '',
                      duration: '60 min',
                      price: '72€',
                    },
                  ]
                },
                {
                  name: 'Beauté relaxant',
                  alternatives: [
                    {
                      description: 'Enveloppement et Modelage au Beurre de Karité.',
                      duration: '75 min',
                      price: '80€',
                    },
                  ]
                },
                {
                  name: 'Soin Nutri-douceur',
                  alternatives: [
                    {
                      description: 'Soin massge visage et corps relaxant et nourissant',
                      duration: '90 min',
                      price: '90€',
                    },
                  ]
                },
                {
                  name: 'Le californien',
                  alternatives: [
                    {
                      description: 'Relaxant par excellence, composé de longs effleurages, pétrissages musculaires. C\'est une véritable relaxation musculaire, idéale en récupération.',
                      duration: '45 min',
                      price: '57€',
                    },
                    {
                      description: '',
                      duration: '60 min',
                      price: '72€',
                    },
                  ]
                },
   
                {
                  name: 'Le Lomi-Lomi Hawaïen',
                  alternatives: [
                    {
                      description: 'Le modelage Hawaïen baptisé "Lomi-Lomi" est un modelage complet du visage et du corps très relaxant. Sa particularité réside dans le fait que ce sont les avant-bras, les mains et les coudes du donneur qui travaillent. Ainsi, la personne massée est enveloppée par des mouvements lents, rapide et doux. Cette sensation d\'être bercé au gré des vagues de l\'océan est toujours présente. Surprenant pour celui ou celle qui le reçoit.',
                      duration: '75 min',
                      price: '85€',
                    },
                  ]
                },
                {
                  name: 'L\'ABHYANGA - AYURVEDA',
                  alternatives: [
                    {
                      description: 'L\'Abhyanga est un modelage décontractant et profond à l\'huile chaude, du visage,du crâne, du cuir chevelu et du corps. L\'objectif est de restaurer l\'équilibre des énergies,il harmonise le corps et l\'esprit, il apaise les tensions et redonne énergie et vitalité,il lutte contre la fatigue générale, l\'agitation mentale et favorise l\'apaisement mental.',
                      duration: '75 min',
                      price: '85€',
                    },
                  ]
                },
                {
                  name: 'Le rituel Brésilien',
                  alternatives: [
                    {
                      description: 'Ce massage vous fait voyager au coeur du brésil avec des senteurs de Mangue et de Papaye, points de relaxation avec des pochons chaud au mélange de fruits et Modelage des méridiens avec la bougie de massage Mangue Papaye.',
                      duration: '60 min',
                      price: '72€',
                    },
                    {
                      description: ' Modelage de 30 min. + gommage',
                      duration: '60 min',
                      price: '72€',
                    },
                    {
                      description: ' Modelage de 60 min. + gommage',
                      duration: '80 min',
                      price: '99€',
                    },
                  ]
                },
                {
                  name: 'Modelage zones réflexes des pieds',
                  alternatives: [
                    {
                      description: 'Comment imaginer qu\'en massant les pieds, tout le corps en ressente les bienfaits. Modelage des pieds, pressions des zones réflexes plantaires.',
                      duration: '45 min',
                      price: '45€',
                    },
                  ]
                },
              ]
            }
      

          ]
        }, // Soin du corps






      ],

    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
