<template>
  <div class="main-content">
    <FirstSiteSection id="sec-home ">
      <template #left>
        <div class="main-title">
          <h2>Maintenance</h2>
          <h1>En cours</h1>
        </div>
        <div class="main-quote">
          <q>Notre site se refait une beauté ! Revenez nous voir  plus tard ...</q>
        </div>
      </template>
    </FirstSiteSection>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import FirstSiteSection from '@/components/FirstSection'
@Component({
  components: {
    FirstSiteSection,
  }
})
export default class Maintenance extends Vue {



}

</script>
