import { ActionContext } from 'vuex'
import { Page } from '@/models/Page'
import { Strapi } from '@/configs/Global.config'
import { API } from '@/models/API'


declare interface PageState
{
  isPageLoaded: boolean
  page: Page

}

declare type GlobalActionContext = ActionContext<PageState, {}>

const state = () => ({
  isPageLoaded: false,
  page: new Page({}),

})

// getters
const getters = {
  isPageLoaded: (state: PageState) => state.isPageLoaded,
  getPage: (state: PageState) => state.page,

}

// actions
const actions = {
  
  
  loadPage (context: GlobalActionContext, pageUid: string) {
    context.commit('setPageLoadState', false)
    const api = new API(Strapi.hostname)
    return api.getByUid<Page>('pages', pageUid)
    .then(data => {
      context.commit('setPage', data)
    })
    .finally(() => {
      context.commit('setPageLoadState', true)
    })

  },

  
}

// mutations
const mutations = {
  setPage (state: PageState, page: Page) {
    state.page = new Page(page)
  },
  setPageLoadState (state: PageState, status: boolean) {
    state.isPageLoaded = status
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}