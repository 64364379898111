import { Carousel } from '@/models/Carousel'

export class Page
{
  uid: string = ''
  name: string = ''
  title1: string = ''
  title2: string = ''
  quote?: string = ''

  carousel?: Carousel = new Carousel({})

  sections: any[] = [] 


  constructor(apiObject: any)
  {
    Object.assign(this, apiObject)
    if (apiObject?.carousel) this.carousel = new Carousel(apiObject.carousel)

  }



}

