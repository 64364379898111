<template>
  <footer>
    <div class="footer-row">
      <div class="footer-col">
        <div class="footer-logo">
          <img src="/img/sophie-icon.png">
          <RouterLink class="btn" to="/reservation">
            Je reserve
          </RouterLink>
          <div class="social-list">
            <a href="https://www.facebook.com/marycohr.chatillonsurindre" rel="noreferrer nofollow"><img src="/img/icons/facebook-square-brands.svg"></a>
          </div>
        </div>
      </div>
      <div class="footer-col">
        <h2>Contact</h2>
        <p class="bold">
          {{ siteInfos.name }}
        </p>
        <p>{{ contactInfos.address }}</p>
        <p>{{ contactInfos.address_2 }}</p>
        <a :href="`tel:${contactInfos.phone}`">{{ contactInfos.phone }}</a>
        <a :href="`mailto:${contactInfos.email}`">{{ contactInfos.email }}</a>
      </div>
      <div class="footer-col">
        <h2>Liens</h2>
        <VMenuItem
          v-for="menuItem in menuItems"
          :key="`footer-menu-${menuItem.id}`"
          :menu-item="menuItem" />
      </div>
      <div class="footer-col">
        <h2>Horaires</h2>
        <table>
          <tr v-for="openinghour in contactInfos.openingHours" :key="`footer-openinghours-${openinghour.id}`">
            <td class="bold">
              {{ openinghour.day }} :
            </td>
            <td v-if="openinghour.text">
              {{ openinghour.text }}
            </td>
            <td v-if="!openinghour.text">
              {{ parseTime(openinghour.morning_start) }}
            </td>
            <td v-if="!openinghour.text">
              {{ parseTime(openinghour.morning_end) }}
            </td>
            <td v-if="!openinghour.text">
              &middot;
            </td>
            <td v-if="!openinghour.text">
              {{ parseTime(openinghour.afternoon_start) }}
            </td>
            <td v-if="!openinghour.text">
              {{ parseTime(openinghour.afternoon_end) }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="brands">
      <a
        v-for="(brand, idx) in brands"
        :key="`prestation-link-${idx}`"
        :href="brand.href"
        rel="nofollow noopenner noreferrer"
        target="_blank">
        <img :src="brand.img">
      </a>
    </div>
    <div class="footer-separator" />
    <div class="copyright">
      {{ new Date().getFullYear() }} - Tous droits réservé - <a href="https://romainmnr.com">RM Agency</a>
    </div>
  </footer>
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import VMenuItem from '@/components/VMenuItem'

@Component({
  components: {
    VMenuItem
  }

})
export default class VFooter extends GlobalMixin {

  get contactInfos()
  {
    return this.$store.getters.getContactInfos
  }

  data(){
    return {
      brands: [
        {
          href:'https://www.marycohr.com',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/mary_cohr_paris_vector_logo_small_fa8e9fa0df.png',
        },
        {
          href:'https://biodroga.com/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/BIODROGA_LOGO_main_black_902c24fef0.avif',
        },
        {
          href: 'https://www.clemenceetvivien.com/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/Logo_noir_e3562111_fb51_4e07_8d28_1d88e8f30a6e_3fcc395260.avif',
        },
        {
          href: 'https://www.noham.fr/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/logo_noham_arbre_violine_51e91f5467.svg',
        },
        {
          href:'https://www.yumi-beauty.com/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/YUMI_BEAUTY_NOIR_aff0547620.png',
        },
        {
          href:'https://www.artdeco.com/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/Artdeco_logo_Beauty_Shop_2022_bc1712eed6.png',
        },

        {
          href: '#',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/complements_alimentaires_natavea_c484eb3ea1.png'
        },
        {
          href: '#',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/logonaturege_0385436a0f.png'
        },
        {
          href: 'https://elixirs-co.com/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/logo_elixrs_and_co_6daac3e0ef.png'
        },
        {
          href: 'https://lmp-sante.fr/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/logo_honrizontal_2_59dbeeca83.svg'
        },
        {
          href: 'https://baija.com/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/baija_a90277160e.png'
        },


        {
          href: 'https://ikita.fr/',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/ikita_136b52a0ac.png'
        },

        // {
        //   href: 'https://pierauge.com/',
        //   img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/signature_PA_Bleue_fd1a8697fb.png'
        // },
        // {
        //   href: 'https://www.lessavonsduberry.fr/',
        //   img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/les_savons_du_berry_84d54cd6e7.png'
        // },

      ]
    }
  }
  

  parseTime(value)
  {
    if(!value || value === '00:00:00') return ''
    const hs = value.split(':')
    return `${Number(hs[0])}h${hs[1] !== '00' ? Number(hs[1]) :  ''}`
  }
}
</script>
