import { ImageLink } from '@/models/ImageLink'


export class Carousel
{
  id: string = ''
  images: ImageLink[] = []
  delay: number = 1000
  width: string = ''
  height: string = ''
  borderColor: string = ''
  autoSlide = true
  hover = true

  constructor(apiObject: any)
  {
    Object.assign(this, apiObject)
    if (apiObject?.images) this.images = apiObject.images.map((e: any) => new ImageLink(e))
  }


  get imageURLs ()
  {
    return this.images.map(e => e.image.fullURL) || []
  }

}

