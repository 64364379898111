<template>
  <div class="main-content">
    <VSection
      class="section-bg-white"
      :boxed="true">
      <h1 class="section-title">
        MENTIONS LÉGALES
      </h1>
      <p>
        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Institut Sophie l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
      </p>
      <h3>
        Edition du site 
      </h3>
      <p>
        Le présent site, accessible à l'URL institutsophie.fr (le « Site »), est édité par :
      </p>
      <p>
        EURL Eleyse Beauté , société au capital de 4500 euros, inscrite au R.C.S. de CHATEAUROUX sous le numéro Chateauroux B 482 839 669482839669 RM XXX, dont le siège social est situé au 24 Bd du général Leclerc, représenté(e) par Sophie Meunier dûment habilité(e)
      </p>
      <p>
        Le numéro individuel TVA de l'éditeur est : FR74482839669.
      </p>
      <h3>
        Hébergement
      </h3>
      <p>
        Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).
      </p>
      <h3>
        Directeur de publication 
      </h3>
      <p>
        Le Directeur de la publication du Site est Sophie Meunier .
      </p>
      <h3>
        Nous contacter 
      </h3>
      <ul>
        <li>Par téléphone : +33254389105</li>
        <li>Par email : institut.sophie@wanadoo.fr</li>
        <li>Par courrier : 24 Bd du général Leclerc, 36700 Châtillon-sur-Indre</li>
      </ul>
      <h3>
        Webmaster
      </h3>
      <ul>
        <li>Par téléphone : +33677550942</li>
        <li>Par email : made-it@romainmnr.com</li>
        <li>Par courrier : 24 Bd du général Leclerc, 36700 Châtillon-sur-Indre</li>
      </ul>

      <h3>
        Données personnelles
      </h3>
      <p>
        Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section "Charte de Protection des Données Personnelles", conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).
      </p>
    </VSection>

    <VSection
      class="section-bg-white"
      :boxed="true">
      <h1 class="section-title">
        Médiation 
      </h1>
      <p>
        <img src="/img/CM2C-CARTE-VISITE.png" width="400px" alt="Médiation contact">
      </p>
    </VSection>
  </div>
</template>

<script>
import FirstSiteSection from '@/components/FirstSection'
import VSection from '@/components/VSection'
import Carousel from '@/components/Carousel'
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import VMap from '@/components/VMap.vue'

@Component({
  components: {
    FirstSiteSection,
    VSection,
    Carousel,
    VMap,
  }
})
export default class Page extends GlobalMixin {

  data() {
    return {
      images: [],
      carousel: {
        imageURLs: [
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_393847904_f1883076d7.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_223522153_5061460fbb.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_270441506_dd2ae4baf0.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_201202488_426d139d57.jpeg',

        ],
        width:"40vw",
        height:"40vh",
        autoSlide:true,
        slideInterval:4000,
        borderColor:"green",
        hover:true ,
      }
    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
