import { Strapi } from '@/configs/Global.config'
export interface ImageFomat {

  hash: string 
  ext: string
  mime: string 
  url: string
  name: string 
  path: string 

  width: number
  height: number
  size: number
}


export class Image
{
  id: string = ''
  name: string = ''
  alternativeText: string = ''
  caption: string = ''

  width: number = 0
  height: number = 0

  hash: string = ''
  ext: string = ''
  mime: string = ''
  size: number = 0
  url: string = ''
  previewUrl: string = ''
  provider: string = ''
  provider_metadata: string = ''
  created_at: string = ''
  updated_at: string = ''

  formats: ImageFomat[] = []

  constructor(apiObject: any)
  {
    Object.assign(this, apiObject)
  }


  get fullURL() {
    return Strapi.hostname +  this.url
  }

}

