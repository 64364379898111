import { Image } from '@/models/Image'
import { Link } from '@/models/Link'


export class ImageLink
{
  id: string = ''
  image: Image = new Image({})
  link: Link = new Link({})
  
  constructor(apiObject: any)
  {
    Object.assign(this, apiObject)
    if (apiObject?.image) this.image = new Image(apiObject.image)
    if (apiObject?.link) this.link = new Link(apiObject.link)
  }

}

