<template>
  <div class="main-content">
    <FirstSiteSection id="sec-home">
      <template #left>
        <div class="main-title">
          <h2>Contactez</h2>
          <h1>Nous</h1>
        </div>
        <div class="main-quote">
          <q>Conseils et écoute sont nos priorités</q>
        </div>
      </template>
      <template #right>
        <Carousel
          
          :images="carousel.imageURLs"
          :auto-slide="carousel.autoSlide"
          :slide-interval="carousel.delay"
          :border-color="carousel.borderColor"
          :hover="carousel.hover" />
      </template>
    </FirstSiteSection>

    <VMap
      class="section-bg-green"
      :boxed="true" />
  </div>
</template>

<script>
import FirstSiteSection from '@/components/FirstSection'
import VSection from '@/components/VSection'
import Carousel from '@/components/Carousel'
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import VMap from '@/components/VMap.vue'

@Component({
  components: {
    FirstSiteSection,
    VSection,
    Carousel,
    VMap,
  }
})
export default class Page extends GlobalMixin {

  data() {
    return {
      images: [],
      carousel: {
        imageURLs: [
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_393847904_f1883076d7.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_223522153_5061460fbb.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_270441506_dd2ae4baf0.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_201202488_426d139d57.jpeg',

        ],
        width:"40vw",
        height:"40vh",
        autoSlide:true,
        slideInterval:4000,
        borderColor:"green",
        hover:true ,
      }
    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
