<template>
  <div class="main-content">
    <FirstSiteSection id="sec-home">
      <template #left>
        <div class="main-title">
          <h2>Prenez</h2>
          <h1>Rendez-vous</h1>
        </div>
      </template>
      <template #right>
        <Carousel
          
          
          :images="carousel.imageURLs"
          :auto-slide="carousel.autoSlide"
          :slide-interval="carousel.delay"
          :border-color="carousel.borderColor"
          :hover="carousel.hover" />
      </template>
    </FirstSiteSection>

    <!--<VSection
      class="section-bg-green section-center"
      :boxed="true">
      <div class="prestations-links">
        <a
          v-for="(link, idx) in links"
          :key="`prestation-link-${idx}`"
          class="prestation-link"
          rel=" noopenner"
          target="_blank"
          :href="link.href">
          <img :src="link.img" :alt="link.label">
          <span>{{ link.label }}</span>
        </a>
      </div>
    </VSection>-->
    <VSection
      class="section-bg-gold section-center"
      :boxed="true">
      <h1 class="section-title">
        RESERVATION ET ANNULATION
      </h1>
      <p>
        Afin que nous puissions vous garantir l'horaire de votre choix, nous vous conseillons d'anticiper au maximum votre prise de rendez-vous. 
      </p>
      <p>
        Un acompte peut vous être demandé lors de la réservation. En cas d'empêchement, nous vous demandons d'annuler votre rendez-vous 12H à l'avance par téléphone (par internet si prise de rendez-vous par internet) sinon votre acompte sera perdu.
      </p>
    </VSection>
    <VSection
      class="section-bg-green section-center"
      :boxed="true">
      <h1 class="section-title">
        RETARD
      </h1>
      <p>
        En cas de retard, nous ne manquerons pas de vous proposer la même prestation si notre planning nous le permet, en revanche, dans le cas contraire, nous serons obligés d’écouter le soin.
      </p>
    </VSection>
    <VSection
      class="section-bg-gold section-center"
      :boxed="true">
      <h1 class="section-title">
        SANTÉ
      </h1>
      <p>
        De nombreux soins sont proscris durant la grossesse, nous vous remercions de bien vouloir nous informer de votre condition au moment de la réservation ou dès votre arrivée.
        Tout autre condition particulière de santé doit être signalée.
      </p>
    </VSection>
    <VSection
      class="section-center"
      :boxed="true">
      <div id="planity-intregration" style="padding: 20px 0;" />
    </VSection>
  </div>
</template>

<script>
import FirstSiteSection from '@/components/FirstSection'
import VSection from '@/components/VSection'
import Carousel from '@/components/Carousel'
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import ImageControl from '@/components/ImageControl'

@Component({
  components: {
    FirstSiteSection,
    VSection,
    Carousel,
    ImageControl,
  }
})
export default class Page extends GlobalMixin {

  data() {
    return {
      images: [],
      carousel: {
        imageURLs: [
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_393847904_f1883076d7.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_223522153_5061460fbb.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_270441506_dd2ae4baf0.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_201202488_426d139d57.jpeg',

        ],
        width:"40vw",
        height:"40vh",
        autoSlide:true,
        slideInterval:4000,
        borderColor:"green",
        hover:true ,
      },
      links: [
        {
          href: 'https://onlinebooking.ikosoft.com/15BBD6B232E3783D10A9D0000/FRA',
          label: 'Je reserve via IkoSoft',
          img: 'https://www2.ikosoft.com/app/uploads/2021/02/logoikosofthdfonce-300x158.png'
        },
        {
          href: 'https://beautyplanet.com/institut-sophie-569',
          label: 'Je reserve via Beauty Planet',
          img: 'https://beautyplanet.com/img/svg/Logo_BeautyPlanet-Desktop.svg'
        },

      ]
    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)

    window.planity = {
      key: '-MUJHHYfPdFjLzAejSh8',
      primaryColor: '#bf9d5e',
      container: document.getElementById('planity-intregration'),
    };


    let script1 = document.createElement('script')
    script1.setAttribute('src', 'https://d2skjte8udjqxw.cloudfront.net/widget/production/2/polyfills.latest.js')
    document.head.appendChild(script1)

    let script2 = document.createElement('script')
    script2.setAttribute('src', 'https://d2skjte8udjqxw.cloudfront.net/widget/production/2/app.latest.js')
    document.head.appendChild(script2)
  }



}

</script>
