
import Vue from "vue"
import Router from "vue-router"

//-- Import custom components:
import Page from '@/pages/Page.vue'
import ErrorPage from '@/pages/ErrorPage.vue'
import Maintenance from '@/pages/Maintenance.vue'

import Home from '@/pages/manual/Home.vue'
import Contact from '@/pages/manual/Contact.vue'
import Legal from '@/pages/manual/legal.vue'
import Reservations from '@/pages/manual/Reservations.vue'
import Prestations  from '@/pages/manual/Prestations.vue'
import PrestationsVisage from '@/pages/manual/prestations/PrestationsVisage.vue'
import PrestationsCorps from '@/pages/manual/prestations/PrestationsCorps.vue'
import PrestationsEpilations from '@/pages/manual/prestations/PrestationsEpilations.vue'
import PrestationsMainsPieds from '@/pages/manual/prestations/PrestationsMainsPieds.vue'
import PrestationsMaquillage from '@/pages/manual/prestations/PrestationsMaquillage.vue'
import PrestationsRegard from '@/pages/manual/prestations/PrestationsRegard.vue'
import PrestationsCartesCadeaux from '@/pages/manual/prestations/PrestationsCartesCadeaux.vue'


//-- Load Vue Router
Vue.use(Router);

//-- Configure Routing
const router = new Router({
  routes:[
    { name:'accueil', path: '/', redirect: '/accueil'},

    {
      name:'maintenance',
      path: '/maintenance',
      component: Maintenance
    },
    { name:'error', path: '/error', component: ErrorPage},
    // { name:'page', path: '/:pUid', component: Page},


    { name:'Home', path: '/accueil', component: Home},
    { name:'Contact', path: '/contact', component: Contact},
    { name:'Reservation', path: '/reservation', component: Reservations},

    {
      name:'Prestations',
      path: '/prestations',
      component: Prestations,    
    },
    {
      name:'PrestationsVisage',
      path: '/prestations/visage',
      component: PrestationsVisage,    
    },
    {
      name:'PrestationsCorps',
      path: '/prestations/corps',
      component: PrestationsCorps,    
    },

    {
      name:'PrestationsEpilations',
      path: '/prestations/epilations',
      component: PrestationsEpilations,    
    },
    {
      name:'PrestationsMainsPieds',
      path: '/prestations/mais-et-pieds',
      component: PrestationsMainsPieds,    
    },
    {
      name:'PrestationsRegard',
      path: '/prestations/regard',
      component: PrestationsRegard,    
    },
    {
      name:'PrestationsMaquillage',
      path: '/prestations/maquillage',
      component: PrestationsMaquillage,    
    },
    {
      name:'PrestationsCartesCadeaux',
      path: '/prestations/cartes-cadeaux',
      component: PrestationsCartesCadeaux,    
    },
    {
      name:'Legal',
      path: '/mentions-legales',
      component: Legal,    
    },
    
    

    

    { path: '*', redirect: { name: 'error' }, props: { msg: 'Page introuvable' }},
  ]
})


export default router