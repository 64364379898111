<template>
  <PrestationsPage
    title_1="Soins"
    title_2="Visages"
    :sections="sections"
    :images="images" />
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import PrestationsPage from '@/components/PrestationsPage'

@Component({
  components: {
    PrestationsPage
  }
})

export default class Page extends GlobalMixin {

  data() {
    return {
      images: undefined,

      sections: [
        {
          title: 'Les soins du visages',
          subSections: [
            {
              title: '',
              items: [
                {
                  name: 'Consultation de Cornéothérapie ®',

                  alternatives: [
                    {
                      description: 'Offert pour l\'achat d\'une cure et suivi.',
                      duration: '30 min',
                      price: '30€',
                    },
                    {
                      description: '',
                      duration: '45 min',
                      price: '45€'
                    },
                    {
                      description: '',
                      duration: '60 min',
                      price: '60€'
                    }
                  ]
                },





              ]
            },

            {
              title: 'Soin néttoyant / pureté',
              items: [
                // {
                //   name: 'Catioclean',
                //   alternatives: [
                //     {
                //       description: '1er soin nettoyant.',
                //       duration: '30 min',
                //       price: '35€',
                //     }
                //   ]
                // },
                // {
                //   name: 'Soin l\'Expérience',
                //   alternatives: [
                //     {
                //       description: 'Se concentrer sur une zone en particulier (Découverte, Yeux et Lèvres, Homme).',
                //       duration: '30 min',
                //       price: '35€',
                //     }
                //   ]
                // },
                {
                  name: 'Multi pureté',
                  alternatives: [
                    {
                      description: 'Soin purifiant • Anti-Imperfections.',
                      duration: '50 min',
                      price: '52€',
                    }
                  ]
                }
              ]
            },

            {
              title: 'Soin beauté nature',
              items: [
                {
                  name: 'Phytoxygéne',
                  alternatives: [
                    {
                      description: 'Soin Oxygénant Détoxifiant.',
                      duration: '50 min',
                      price: '71€',
                    }
                  ]
                },
                {
                  name: 'Beauté aromatique',
                  alternatives: [
                    {
                      description: 'Soin aux Huiles • Essentielles Personnalisé.',
                      duration: '50 min',
                      price: '65€',
                    }
                  ]
                },
                {
                  name: 'Soin de saison (Artdéco)',
                  alternatives: [
                    {
                      description: 'Beauté impérial: Soin Hydratant à la protéine de soie.',
                      duration: '50 min',
                      price: '65€',
                    },
                    {
                      description: 'Rituel Vitali\'Thé: Soin hydratant &amp; énergisant',
                      duration: '50 min',
                      price: '65€',
                    },
                    {
                      description: 'Rituel Rose d\'Orient: Soin hydratant &amp; régénérant',
                      duration: '50 min',
                      price: '65€',
                    }
                  ]

                },
        

              ]
            },

  
                

            
                  

            {
              title: 'Soin éclat / peeling',
              subtitle: 'Existe en version peaux sensibles & toutes peaux.',
              items: [
                {
                  name: 'Soin vitaminé',
                  alternatives: [
                    {
                      description: 'Soin éclat à la vitamine C.',
                      duration: '50 min',
                      price: '50€',
                    } 
                  ]
                },
                {
                  name: 'Dermo-peeling',
                  alternatives: [
                    {
                      description: 'Soin peeling Rénovateur de Peau.',
                      duration: '50 min',
                      price: '90€',
                    }
                  ]
                },
                {
                  name: 'Soin Acid Expert',
                  alternatives: [
                    {
                      description: 'Peeling aux acides - en cure pour un traitement en profondeur',
                      duration: '45 min',
                      price: '85€',
                    }
                  ]
                }
              ]
            },
        
            {
              title: 'Soin personalisé',
              subtitle: 'Soin complet, personnalisé en fonction du type et de la condition de la peau, pour répondre à chaque besoin. Il révèlera la beauté et l\'équilibre de votre peau.',
              items: [
                {
                  name: 'L\'émotion',
                  alternatives: [
                    {
                      description: 'Pier Augé',
                      duration: '60 min',
                      price: '70€',
                    } 
                  ]
                },
                // {
                //   name: 'L\'éternel',
                //   alternatives: [
                //     {
                //       description: 'Véritable moment de « lâcher prise » avec un massage spécifique Anti-âge. (Pier Augé)',
                //       duration: '60 min',
                //       price: '70€',
                //     },
                //     {
                //       duration: '90 min',
                //       price: '85€',
                //     }
                //   ]
                // },
                {
                  name: 'Catiovital jeunesse',
                  alternatives: [
                    {
                      description: 'Soin Anti Âge immédiat avec Energie Cellulaire, Personnalisé.',
                      duration: '75 min',
                      price: '77€',
                    }
                  ]
                },
                {
                  name: 'Catiovital nouvelle jeunesse soleil',
                  alternatives: [
                    {
                      description: 'Pour Préparer et réparer sa peau au soleil.',
                      duration: '75 min',
                      price: '77€',
                    }
                  ]
                },
                {
                  name: 'Catiovital double jeunesse',
                  alternatives: [
                    {
                      description: 'Soin Anti-Âge +++ immédiat avec Energie Cellulaire.',
                      duration: '75 min',
                      price: '84€',
                    }
                  ]
                },
              ]
            },


            {
              title: 'Soin anti-âge / fermeté',
              items: [
                {
                  name: 'Soin Visage aux feuilles d’Or & au Caviar',
                  alternatives: [
                    {
                      description: 'Fraîcheur, hydratation et vitalité, éclat & rides lissées',
                      duration: '60 min',
                      price: '99€',
                    }
                  ]
                },
                {
                  name: 'Âge firming',
                  alternatives: [
                    {
                      description: 'Soin Lifting Fermeté Manuel (Visage, Cou et Décolletée).',
                      duration: '60 min',
                      price: '97€',
                    }
                  ]
                },
                {
                  name: 'Catiovital lifting',
                  alternatives: [
                    {
                      description: 'Soin Lifting Fermeté avec Stimulation Musculaire.',
                      duration: '60 min',
                      price: '84€',
                    }
                  ]
                },
                {
                  name: 'Cryo-restructurel',
                  alternatives: [
                    {
                      description: 'Soin Innovant Lifting Biologique. Proposé en cure de 3 ou 5 soins pour un résultat lifting biologique non-chirurgical.',
                      duration: '60 min',
                      price: '110€',
                    }
                  ]
                },
                {
                  name: 'Âge sign\'s reverse',
                  alternatives: [
                    {
                      description: 'Soin Immunité Anti-Âge (Visage, Cou, Décolletée et Mains).',
                      duration: '60 min',
                      price: '112€',
                    }
                  ]
                }
              ]
            },
            {
              title: 'Les spécifiques',
              items: [
                {
                  name: 'Soin des yeux "Eye Lifting"',
                  alternatives: [
                    {
                      description: 'Anti-Rides, Anti-Cernes, Anti-Poches.',
                      duration: '40 min',
                      price: '59€',
                    }
                  ]
                },
                {
                  name: 'L\'expérience yeux & lèvres',
                  alternatives: [
                    {
                      description: 'Sublimer le regard, protéger le contour de la bouche.',
                      duration: '30 min',
                      price: '40€',
                    }
                  ]
                },
              ]
            },
            { 
              title: 'Lift & shape',
              subtitle: 'oOnda Dinamica - en cure pour un traitement en profondeur',

              items: [
                {
                  name: 'Pour tout type d’imperfection du visage  ',
                  alternatives: [
                    {
                      description: 'vieillissement cutané, atonie, rides, rétention d’eau, poches, cernes',
                      duration: '30 min',
                      price: '99€',
                    }
                  ]
                },
      
              ]
            },

            {
              title: 'Les soins massage',
              items: [
                {
                  name: 'Digito pression visage',
                  alternatives: [
                    {
                      description: 'Technique d\'origine japonaise qui favorise la circulation de l\'énergie vitale par pressions.',
                      duration: '45 min',
                      price: '57€',
                    }
                  ]
                },
                {
                  name: 'Massage japonais du visage',
                  alternatives: [
                    {
                      description: 'Soin de massage du visage anti-rides est une méthode de lifting naturel avec un effet rajeunissant; il permet également de combattre le stress et de rééquilibrer l\'organisme en procurant une profonde relaxation.',
                      duration: '60 min',
                      price: '70€',
                    },
                    {
                      description: '+ Avec Gommage & Masque.',
                      duration: '90 min',
                      price: '87€',
                    }
                  ]
                },
              ]
            }
      

          ]
        }, // Soin du visage






      ],

    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
