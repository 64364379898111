import { Link } from '@/models/Link'

export interface MenuItemApiObj
{
  isInMainMenu?: boolean
  isInFooterMenu?: boolean
  link?:any
  order?: number
}

export class MenuItem
{
  id: string = ''
  link: Link = new Link()
  isInMainMenu: boolean = true
  isInFooterMenu: boolean = true
  order: number = 1

  constructor(apiObject?: MenuItemApiObj)
  {
    Object.assign(this, apiObject)
    if (apiObject?.link) this.link = new Link(apiObject.link)
  }



}

