<template>
  <div class="main-content">
    <FirstSiteSection id="sec-home">
      <template #left>
        <div class="main-title">
          <h2>L'institut</h2>
          <h1>Sophie</h1>
        </div>
        <div class="main-quote">
          <q>La Beauté Autrement</q>
        </div>
      </template>
      <template #right>
        <Carousel
          
          :images="carousel.imageURLs"
          :auto-slide="carousel.autoSlide"
          :slide-interval="carousel.delay"
          :border-color="carousel.borderColor"
          :hover="carousel.hover" />
      </template>
    </FirstSiteSection>
    <!-- <VSection
      class="section-bg-gold"
      :boxed="true">
      <h1 class="section-title">
        Fermeture Annuelle
      </h1>
      <p>Votre institut sera fermé du <b>15 août au 18 août</b> et du <b>6 septembre au 17 septembre</b></p>
    </VSection> -->
    <VSection
      class="section-bg-green"
      :boxed="true">
      <h1 class="section-title">
        Sophie, esthéticienne-cosméticienne & Cornéothérapeute 
      </h1>
      <p>
        Avec plus de 35 ans de savoir-faire, Sophie et son équipe d'esthéticiennes sauront vous apporter leurs conseils beautés.<br>
        La cornéothérapie&reg; est à la frontière entre l'esthétique traditionnel et la dermatologie: la connaissance, l'expertise de la peau.<br>
        Notre objectif est de s'attaquer à la cause des altérations cutanées et non simplement de traiter les effets visibles.<br>
        Un bilan cutané est établi afin de déterminer les besoins de la peau et un programme de soin est donné ainsi qu'un traitement a domicile afin d'améliorer l'état de peau.<br>
      </p>
    </VSection>



    <VSection
      class="section-bg-gold section-center"
      :boxed="true">
      <h1 class="section-title">
        Sauna Dôme
      </h1>
      <div class="section-row">
        <div style="width: 45%;">
          <ImageControl
            src="https://strapi.romainmnr.com/institutsophie.fr/uploads/publication_photo_MINN_Wf_139448c64f.jpg" 
            class="green" 
            :hover="false" />
        </div>


        <div>
          <p>
            Sauna japonais  aux infrarouges longs & aux pierres minérales
          </p>
          <p>
            Le sauna infrarouges est depuis toujours connu par les japonais pour ses vertus apaisantes et détoxifiantes.
            Comme le soleil, les deux dômes du sauna et le matelas chauffant génèrent des infrarouges longs dénués de rayon UV.
          </p>

          <p>
            <b>DETOX</b> &bull;
            <b>AMINCISSANT</b> &bull;
            <b>RELAXANT</b>
          </p>
        </div>
      </div>
    </VSection>

    <VSection
      class="section-bg-white"
      :boxed="true">
      <p>
        <img src="https://strapi.romainmnr.com/institutsophie.fr/uploads/maitre_artisan_050bb630cc.png" height="200px">
      </p>
    </VSection>
  </div>
</template>

<script>
import FirstSiteSection from '@/components/FirstSection'
import VSection from '@/components/VSection'
import Carousel from '@/components/Carousel'
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import ImageControl from '@/components/ImageControl'

@Component({
  components: {
    FirstSiteSection,
    VSection,
    Carousel,
    ImageControl,
  }
})
export default class Page extends GlobalMixin {

  data() {
    return {
      images: [],
      carousel: {
        imageURLs: [
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_393847904_f1883076d7.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_223522153_5061460fbb.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_270441506_dd2ae4baf0.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_201202488_426d139d57.jpeg',

        ],
        width:"40vw",
        height:"40vh",
        autoSlide:true,
        slideInterval:4000,
        borderColor:"green",
        hover:true ,
      }
    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
