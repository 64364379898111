<template>
  <VSection :id="id" class="section-full-size">
    <iframe
      width="100%"
      height="400"
      style="border:0"
      loading="lazy"
      allowfullscreen
      referrerpolicy="no-referrer-when-downgrade"
      :src="`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=INSTITUT+SOPHIE, Châtillon-sur-Indre, France`" />
  </VSection>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import VSection from '@/components/VSection.vue'
import { GoogleMapConfig } from '@/configs/Global.config'

@Component({
  components: {
    VSection
  }
})
export default class VMap extends Vue {
  @Prop({ default: '', type: String }) id

  data() {
    return {
      apiKey : GoogleMapConfig.apiKey
    }
  }


  
}
</script>