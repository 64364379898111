<template>
  <div class="main-content">
    <FirstSiteSection id="sec-home">
      <template #left>
        <div class="main-title">
          <h2>{{ title_1 }}</h2>
          <h1>{{ title_2 }}</h1>
        </div>
      </template>
      <template #right>
        <Carousel
          
          :images="images"
          :auto-slide="carousel.autoSlide"
          :slide-interval="carousel.delay"
          :border-color="carousel.borderColor"
          :hover="carousel.hover" />
      </template>
    </FirstSiteSection>

    <VSection
      v-for="(section, sIdx) in sections"
      :key="`prestations-${sIdx}`"
      class="section-bg-green"
      :boxed="true">
      <h2 class="section-title">
        {{ section.title }}
      </h2>
      <div
        v-for="(subSection, ssIdx) in section.subSections"
        :key="`prestations-${sIdx}-sub-${ssIdx}`"
        class="price-section">
        <h2 v-if="subSection.title">
          {{ subSection.title.toUpperCase() }}
        </h2>
        <span v-if="subSection.subtitle">
          {{ subSection.subtitle }}
        </span>
        <table>
          <tbody>
            <template v-for="(item, itemIdx) in subSection.items">
              <tr :key="`prestations-${sIdx}-sub-${ssIdx}-item-${itemIdx}`">
                <td class="name">
                  {{ item.name.toUpperCase() }}
                </td>
              </tr>

              <tr 
                v-for="(alt, altIdx) in item.alternatives"
                :key="`prestations-${sIdx}-sub-${ssIdx}-item-${itemIdx}-alt-${altIdx}`"
                class="underline">
                <td class="description ">
                  {{ alt.description }}
                </td>
                <td class="time">
                  {{ alt.duration }}
                </td>
                <td class="price">
                  {{ alt.price }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <slot name="prestationFooter" />
    </VSection>
    <slot />
  </div>
</template>

<script>
import FirstSiteSection from '@/components/FirstSection'
import VSection from '@/components/VSection'
import Carousel from '@/components/Carousel'
import GlobalMixin from '@/mixins/global.mixin'
import ImageControl from '@/components/ImageControl'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    FirstSiteSection,
    VSection,
    Carousel,
    ImageControl,
  }
})



export default class Page extends GlobalMixin {
  @Prop({ default: "", type: String }) title_1
  @Prop({ default: "", type: String }) title_2
  @Prop({ default: [], type: Array }) sections
  @Prop({ default: () =>([
    'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
    'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
    'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_393847904_f1883076d7.jpeg',
    'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_223522153_5061460fbb.jpeg',
    'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_270441506_dd2ae4baf0.jpeg',
    'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_201202488_426d139d57.jpeg',

  ]), type: Array }) images


  data() {
    return {
      carousel: {
        width:"40vw",
        height:"40vh",
        autoSlide:true,
        slideInterval:4000,
        borderColor:"green",
        hover:true ,
      },

    }
  }


}

</script>
