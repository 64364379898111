<template>
  <transition name="fade-blur">
    <div class="full-loader" />
  </transition>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component({

})
export default class FullLoader extends Vue {


}
</script>
