<template>
  <section :id="id">
    <div
      class="section-wrapper" :class="[
        boxed && 'boxed'
      ]">
      <slot />
    </div>
  </section>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'


@Component({

})
export default class VSection extends Vue {
  @Prop({ default: '', type: String }) id
  @Prop({ default: false, type: Boolean }) boxed


}
</script>
