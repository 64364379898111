import axios from 'axios'

export class API
{
  readonly hostname: string
  

  constructor(hostname: string)
  {
    this.hostname = hostname
  }

  
  getSingleType<T>(collection: string): Promise<T>
  {
    return axios.get(`${this.hostname}/${collection}`)
      .then(response => {
        return  <T>(response.data)
      })
  }

  get<T>(collection: string, id: string): Promise<T>
  {
    return axios.get(`${this.hostname}/${collection}/${id}`)
      .then(response => {
        return  <T>(response.data)
      })
  }

  getByUid<T>(collection: string, uid: string): Promise<T>
  {
    return axios.get(`${this.hostname}/${collection}`, { params : { uid: uid }})
      .then(response => {
        if (response.data && response.data.length === 1) {
          return <T>(response.data[0])
        }else{
          return Promise.reject(new Error('Not found'))
        }
      })
  }
  getAll<T>(collection: string, filter?: any): Promise<Array<T>>
  {
    return axios.get(`${this.hostname}/${collection}`, { params : filter})
      .then(response => {
        if (response.data && Array.isArray(response.data)) {
          return response.data.map((e) => <T>(e))
        }else{
          return []
        }
      })
  }


}

