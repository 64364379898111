<template>
  <div class="main-content">
    <FirstSiteSection id="sec-home">
      <template #left>
        <div class="main-title">
          <h2>Nos</h2>
          <h1>Prestations</h1>
        </div>
      </template>
      <template #right>
        <Carousel
          
          :images="carousel.imageURLs"
          :auto-slide="carousel.autoSlide"
          :slide-interval="carousel.delay"
          :border-color="carousel.borderColor"
          :hover="carousel.hover" />
      </template>
    </FirstSiteSection>

    <VSection
      class="section-bg-green section-center"
      :boxed="true">
      <div class="prestations-links">
        <router-link 
          v-for="(link, idx) in links"
          :key="`prestation-link-${idx}`"
          class="prestation-link"
          :to="link.to">
          <img :src="link.img" :alt="link.label">
          <span>{{ link.label }}</span>
        </router-link>
      </div>

      <div>
        <a class="btn no-width white" href="https://strapi.romainmnr.com/institutsophie.fr/uploads/tarifs_institut_sophie_2022_e719b485f6.pdf">
          Télécharger nos tarifs
        </a>
      </div>
    </VSection>
  </div>
</template>

<script>
import FirstSiteSection from '@/components/FirstSection'
import VSection from '@/components/VSection'
import Carousel from '@/components/Carousel'
import GlobalMixin from '@/mixins/global.mixin'
import { Component } from 'vue-property-decorator'
import ImageControl from '@/components/ImageControl'

@Component({
  components: {
    FirstSiteSection,
    VSection,
    Carousel,
    ImageControl,
  }
})
export default class Page extends GlobalMixin {

  data() {
    return {
      images: [],
      carousel: {
        imageURLs: [
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_393847904_f1883076d7.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_223522153_5061460fbb.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_270441506_dd2ae4baf0.jpeg',
          'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_201202488_426d139d57.jpeg',

        ],
        width:"40vw",
        height:"40vh",
        autoSlide:true,
        slideInterval:4000,
        borderColor:"green",
        hover:true ,
      },

      links: [
        {
          to: { name: 'PrestationsVisage' },
          label: 'Les Soins visages',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_393847904_f1883076d7.jpeg'
        },
        {
          to: { name: 'PrestationsCorps' },
          label: 'Les Soins Corps',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_204224749_7101333e7f.jpeg'
        },
        {
          to: { name: 'PrestationsRegard' },
          label: 'Embellisement du regard',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_275795836_c0c42cc9c0.jpeg'
        },
        {
          to: { name: 'PrestationsEpilations' },
          label: 'Épilations',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_223522153_5061460fbb.jpeg'
        },
        {
          to: { name: 'PrestationsMaquillage' },
          label: 'Maquillage',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_245137649_2f065f2801.jpeg'
        },
        {
          to: { name: 'PrestationsMainsPieds' },
          label: 'Main et pieds',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/Adobe_Stock_270441506_dd2ae4baf0.jpeg'
        },
        {
          to: { name: 'PrestationsCartesCadeaux' },
          label: 'Cartes cadeaux',
          img: 'https://strapi.romainmnr.com/institutsophie.fr/uploads/mockup_v1_copy_a35e3d3499.png'
        },
      ]

    }
  }

  mounted()
  {
    this.$store.commit('setPageLoadState', true)
  }





}

</script>
