import { Page } from '@/models/Page'

export class Link
{
  label: string = ''
  href: string = ''
  alt: string = ''
  ref: string = ''
  page?: Page


  constructor(apiObject?: any)
  {
    Object.assign(this, apiObject)
    if (apiObject?.page) this.page = new Page(apiObject.page)

  }

  getHref()
  {
    return (this.page ?  `/${this.page.uid}` : this.href) || ''
  }


  get isExternal() {
    return this.getHref().startsWith('https://') || this.getHref().startsWith('http://')
  }
}

